import React from "react";
import { usePages } from "../dashboard/PagesContext";
import { Route, Routes } from "react-router-dom";
import DashboardContent from "../dashboard/Dashboard";
import Visualization from "../dashboard/Visualization";
import { useAuth } from '../auth/AuthContext';
import ErrorPage from "../utils/ErrorPage";
import LoadingBackdrop from "../../screens/Loading";


export default function DefaultRoutes() {
    const { flatPages, currentPage, priming} = usePages();
    const currentPath = window.location.pathname;

    if (!flatPages) {
        return <LoadingBackdrop loading={priming}/>;
      }
    
    return (
        <Routes>
            {flatPages.map((page, index) => (
                <Route
                exact={true}
                key={page.pageUid + index}
                path={page.path || currentPath}
                loader={async ({ page }) => {
                    return fetch(
                      `${page.path}`
                    );
                }}
                element={
                    <DashboardContent>
                        <Visualization currentPageObj={page} key={currentPage} />
                    </DashboardContent>
                }
            />
            ))}
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
}