import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import Input from '../utils/Input';
import Button from '../utils/Button';
import { useCustomAuth0 } from './CustomAuth0Context';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
  },
}));

const initialValues = {
  email: '',
};



export default function LoginForm() {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const { setEmail } = useCustomAuth0();

  const handleInputChange = (e) => {
    const { value } = e.target;
    setValue(value);
  }


  const handleSubmit = (e) => {
    setEmail(value);
    };

  return (
    <Grid className={classes.form} container>
      <Grid item xs={8}>
        <Input
          label="Email"
          name="email"
          onChange={handleInputChange}
          value={value}
        />
      </Grid>
      <Grid item xs={12}>
        <div>
          <Button
            type="submit"
            text="Submit"
            form="loginForm"
            onClick={handleSubmit}
          />
        </div>
      </Grid>
    </Grid>
  );
}