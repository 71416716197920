import React, { useState} from 'react';

function ErrorPage({error, resetErrorBoundary}) {
  const [errorState, setErrorState] = useState({ hasError: false, errorMessage: null});
  const handleErrors = (errorMessage, errorInfo) => {
        console.error('Error caught by ErrorBoundary:', errorMessage, errorInfo);
        setErrorState({ hasError: true, errorInfo });
      };
  window.addEventListener('error', handleErrors);
  const errorMessage = error.message || "Unknown error";
    return (
      <div>
        <h1>Something went wrong</h1>
        <p>{errorMessage}</p>
      </div>
    );
  }

export default ErrorPage;