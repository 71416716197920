import React, { useEffect, useState } from "react";
import { auth0Config } from "../../auth0-config";
import { Auth0Provider } from '@auth0/auth0-react';
import { useCustomAuth0 } from "./CustomAuth0Context";
import { Navigate } from "react-router-dom";
import Login from "../../screens/Login";
import ProvideAuth from "./AuthContext";

export default function Auth0Context({ children }) {
    const { email } = useCustomAuth0();
    let redirectUrl;
    if (window.location.hostname === 'localhost') {
        redirectUrl = `${window.location.protocol}//${window.location.hostname}:7003`;
    } else {
        redirectUrl = `${window.location.protocol}//${window.location.hostname}`;
    };

    console.log(email);

    if (!auth0Config.hasOwnProperty("clientAuth0")) { 
        return (
            <Auth0Provider
                domain={auth0Config.domain}
                clientId={auth0Config.clientId}
                redirectUri={redirectUrl}
            > 
                {children}
            </Auth0Provider>
        );
    } else {
        if (!email) {
            return <Login />
        } else if ((/^[^\s@]+@wawa\.com$/).test(email)) {
            return (
                <Auth0Provider
                    domain={auth0Config.clientAuth0.domain}
                    clientId={auth0Config.clientAuth0.clientId}
                    redirectUri={redirectUrl}
                    scope={auth0Config.clientAuth0.scope}
                    connection={auth0Config.clientAuth0.connection}
        
                >
                    {children}
                </Auth0Provider>
            );
        } else {
            return (
                <Auth0Provider
                    domain={auth0Config.domain}
                    clientId={auth0Config.clientId}
                    redirectUri={redirectUrl}
                > 
                    {children}
                </Auth0Provider>
            );
        }
            
    };
}