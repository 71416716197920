import React, { useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  submit: {
    color: "white",
  },
}));

const Logout = ({ navigateTo = "/login" }) => {
  const [disabled, setDisabled] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const signout = () => {
    setDisabled(true);
    signOut()
      .then(() => {
        navigate(navigateTo);
      })
      .catch((error) => {
        console.error(error);
        setDisabled(false);
      });
  };

  return (
    <div>
      <Button 
        disabled={disabled} 
        onClick={signout} 
        type="submit"
        className={classes.submit} >
        Logout
      </Button>
    </div>
  );
};

export default Logout;
