import React, {
  useState, useContext, createContext, useEffect,
} from 'react';

const customAuth0Context = createContext();

export const useCustomAuth0 = () => useContext(customAuth0Context);

export const useProvideCustomAuth0 = () => {
  const [email, setEmail] = useState(localStorage.getItem('email') || null);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email, setEmail]);

  return {
    email,
    setEmail
  };
};

const ProvideCustomAuth0 = ({ children }) => {
  const auth = useProvideCustomAuth0();
  return (
    <customAuth0Context.Provider value={auth}>
      {children}
    </customAuth0Context.Provider>
  );
};

export default ProvideCustomAuth0;
