import React, { useContext, useState, useEffect, createContext } from 'react';
import { firestore } from '../../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../auth/AuthContext';

const pagesContext = createContext();
export const usePages = () => useContext(pagesContext);

const makePagesTree = (pagesDocsList) => {
  const pagesMap = new Map();
  const pagesTree = [];

  pagesDocsList.forEach((page) => {
    const { pageUid, parent } = page;
    if (!parent) {
      pagesMap.set(pageUid, { ...page, children: [] });
      pagesTree.push(pagesMap.get(pageUid));
    } else {
      const parentPage = pagesMap.get(parent);
      if (parentPage) {
        parentPage.children.push(page);
      }
    }
  });

  return { pagesTree };
};

const getAllDashboards = async () => {
  try {
    const dashboardsDocs = await getDocs(collection(firestore, 'dashboards'));
    const dashboardsList = dashboardsDocs.docs.map((doc) => doc.data());
    const sortedDashboardsList = dashboardsList.sort((a, b) => a.pageNumber - b.pageNumber);
    return { sortedDashboardsList };
  } catch (err) {
    console.error(err, "Error while fetching dashboards.");
    return { sortedDashboardsList: null };
  }
};

const getAllPagesForDashboard = async (dashboardUid) => {
  try {
    const dashboardsRef = collection(firestore, `dashboards/${dashboardUid}/pages`);
    const pagesDocs = await getDocs(dashboardsRef);
    const pagesList = pagesDocs.docs.map((doc) => doc.data());
    const sortedPagesList = pagesList.sort((a, b) => a.pageNumber - b.pageNumber);
    return { pagesDocsList: sortedPagesList };
  } catch (err) {
    console.error(err, "Error while fetching dashboard pages.");
    return { pagesDocsList: null };
  }
};

const getAllPages = async () => {
  try {
    const { sortedDashboardsList } = await getAllDashboards();
    const allPagesDocsList = [];

    for (const dashboard of sortedDashboardsList) {
      const { pagesDocsList } = await getAllPagesForDashboard(dashboard.dashboardUid);
      allPagesDocsList.push(...pagesDocsList);
    }

    const allPagesDocsMap = new Map(allPagesDocsList.map((page) => [page.pageUid, page]));
    return { allPagesDocsList, allPagesDocsMap };
  } catch (err) {
    console.error(err, "Error while fetching dashboard pages.");
    return { allPagesDocsList: null, allPagesDocsMap: null };
  }
};

const getPages = async () => {
  try {
    const { allPagesDocsList, allPagesDocsMap } = await getAllPages();
    const { pagesTree } = makePagesTree(allPagesDocsList);
    const defaultPage = allPagesDocsList.reduce((acc, curr) => {
      if (!acc || acc.pageNumber > curr.pageNumber) {
        return curr;
      }
      return acc;
    }, null);

    return { pagesTree, defaultPage, allPagesDocsMap, allPagesDocsList };
  } catch (err) {
    console.warn('Error while fetching the dashboard doc.');
    console.error(err);
    return { pagesTree: null, defaultPage: null, allPagesDocsMap: null, allPagesDocsList: null };
  }
};

export const useProvidePages = () => {
  const [pagesData, setPagesData] = useState({
    pages: null,
    flatPages: null,
    dashPages: null,
    priming: true,
    currentPage: null,
  });

  const changeCurrentPage = (pageUid) => {
    setPagesData((prevData) => ({ ...prevData, currentPage: pageUid }));
  };

  const auth = useAuth();

  useEffect(() => {
    const fetchPagesDocuments = async () => {
      try {
        const {
          pagesTree,
          defaultPage,
          allPagesDocsMap,
          allPagesDocsList,
        } = await getPages();

        setPagesData((prevData) => ({
          ...prevData,
          pages: pagesTree,
          dashPages: allPagesDocsMap,
          flatPages: allPagesDocsList,
          currentPage: defaultPage?.pageUid,
          priming: false,
        }));
      } catch (error) {
        console.error(error, 'Error fetching documents.');
        setPagesData((prevData) => ({ ...prevData, priming: false }));
      }
    };

    if (auth.fbUser) {
      fetchPagesDocuments();
    }
  }, [auth]);

  return {
    ...pagesData,
    changeCurrentPage,
  };
};

export const ProvidePages = ({ children }) => {
  const pages = useProvidePages();
  return (
    <pagesContext.Provider value={pages}>
      {children}
    </pagesContext.Provider>
  );
};

export default ProvidePages;
