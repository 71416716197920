import React from 'react';
import {
  TextField, FormControl, makeStyles, FormHelperText,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  errorMessage: {
    color: '#ce0606',
  },
  input: {
    minWidth: '95%',
  },
}));

export default function Input({
  name, label, value, onChange, error,
}) {
  const classes = useStyles();

  return (
    <FormControl className={classes.input}>
      <TextField
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
      />
      <FormHelperText className={classes.errorMessage}>{error}</FormHelperText>
    </FormControl>
  );
}