import React, {
    useState, useEffect, useContext, createContext,
  } from 'react';
  import { httpsCallable } from "firebase/functions";
  import { signInWithCustomToken } from "firebase/auth"
  import { useAuth0 } from '@auth0/auth0-react';
  import { functions, auth } from "../../config/firebase";
  import { useCustomAuth0 } from './CustomAuth0Context';

  
  const authContext = createContext();
  
  export const useAuth = () => useContext(authContext);
  
  export const useProvideAuth = () => {
    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        getIdTokenClaims,
      } = useAuth0();
    const {email} = useCustomAuth0();
    const [fbUser, setfbUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [claims, setClaims] = useState(null);
  
    const signOut = () => {
      auth.signOut();
      logout();
    };

    const signInWithAuth0 = async () => {
      if (isAuthenticated && fbUser) return;
      try {
        await loginWithRedirect();
      } catch (e) {
        console.error(e);
      }
    }; 

    const signInWithFirebase = async (tokenClaims) => {
      if (fbUser) return;
      try {
        const authFirebaseResponse = await httpsCallable(functions, "authFirebase")(tokenClaims);
        if (!authFirebaseResponse.data?.token) { 
            throw new Error('No token found in response.');
        } else {
            const fbToken = authFirebaseResponse.data?.token;
            await signInWithCustomToken(auth, fbToken);
            setfbUser(true);
        }
      } catch (e) {
        console.error(e);
        throw new Error('No token found in response.')
      }
    };

    useEffect(() => {
      const auth0SignIn = async () => {
        if (!isAuthenticated) {
          try {
            await signInWithAuth0();
          } catch (e) {
            console.error(e);
          }
        }};
      auth0SignIn(email);
    }, [email]);

    useEffect(() => {
      const getFirebaseToken = async () => {
        if (isAuthenticated && !fbUser) {
          try {
            const tokenClaims = await getIdTokenClaims();
            await signInWithFirebase(tokenClaims);
            setfbUser(true);
            const userClaims = await auth.currentUser.getIdTokenResult(true)
            setCurrentUser(userClaims)
          } catch (e) {
            console.error(e);
            setfbUser(false);
          }
        }};
      getFirebaseToken();
    }, [isAuthenticated]);
  
    return {
      signOut,
      fbUser,
      currentUser,
      signInWithFirebase
    };
  };
  
  const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth();
    return (
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
    );
  };
  
  export default ProvideAuth;