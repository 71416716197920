import ReactDOM from 'react-dom';
import './firebase-config';
import './auth0-config';
import './index.css';
import App from './App';

/* eslint-disable react/jsx-filename-extension */
ReactDOM.render(
  App(),
  document.getElementById('root'),
);
/* eslint-enable react/jsx-filename-extension */
